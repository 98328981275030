import { Component, Inject } from '@angular/core';
import { ResetPasswordService } from './reset-password.service';
import { AppState } from '../../app.service';
import { Router, ActivatedRoute } from "@angular/router";
import { UrlConfig } from '../../../url-config';
import { isValidEmail, isValidPassword } from '@tandfgroup/form-field-validator'
import { DOCUMENT } from '@angular/common';

const TIMEOUT_INTERVAL = 2000;

@Component({
	selector: 'reset-password',
	templateUrl: 'reset-password.component.html',
	styleUrls: ['reset-password.component.scss'],
	providers: [ResetPasswordService],
})
export class ResetPasswordComponent {

	private baseURL: any = new UrlConfig().getBaseApiUrl();

	email: string = '';
	newPwd: string = '';
	cnfPwd: string = '';
	token: string = '';
	errorMessage: string = '';
	isTokenExpired: boolean = false;
	isEmailError: boolean = false;
	isPasswordError: boolean = false;
	isConfirmPasswordError: boolean = false;
	resetPasswordScreen: boolean = false;
	resetPasswordSuccessScreen: boolean = false;
	forgotPasswordScreen: boolean = false;
	emailSentScreen: boolean = false;
	isPasswordCriteriaError: boolean = false;
	resentLink = false;
	emailCannotBeEmpty: string = 'Email address required';
	invalidEmailFormat: string = 'Must be an email address.';
	passwordCannotBeEmpty: string = 'Password cannot be empty';
	confirmPasswordCannotBeEmpty: string = 'Confirm password cannot be empty';
	passwordNoSameMessage: string = 'Passwords don\'t match. Try again?';
	brand: string = 'ubx';
	resentNotificationMsg: string = 'If the email address was found, a password reset email has been resent';
	sentNotificationMsg: string = 'If the email address is connected to a Taylor & Francis account, you will receive an email with instructions on how to reset your password.';
	passwordHint: string = "Password must be at least 8 characters long and include at least one of each of 0-9, a-z, A-Z and symbol (e.g. ! # ? $)"
	standardPasswordHint = this.passwordHint;
	user: any = {};
	idToken = null;
	officeLogin = this.baseURL + 'user/auth/office';
	readError: boolean = false;
	clientId: string;
	private timer;
	clientDetails: any;
	viewPswd: string = 'icon-eye-blocked';
	viewcnfPswd: string = 'icon-eye-blocked';
	newPassMatchCnfPass:string='Passwords don’t match. Try again?'
	isAuthorizeFlow = false;

	constructor(private resetPasswordRegister: ResetPasswordService,
		private appState: AppState, private _route: Router, private activatedRoute: ActivatedRoute,
		@Inject(DOCUMENT) private document: Document) {
	}

	ngOnInit() {
		this.clientDetails = this.appState.getClientConfig();
		this.brand = this.appState.get('queryParams')['brand'];
		this.activatedRoute.queryParams.subscribe((param: any) => {
			this.clientId = param.client_id;
			this.isAuthorizeFlow = Boolean(param.authorize);
			if ((param['reset'])) {
				this.forgotPasswordScreen = true;
			} else if ((param['verify'])) {
				this.verifyToken(decodeURIComponent(param['user']), param['token']);
				this.email = param['user'];
			}
		});
	}

	ngAfterViewInit() {
		$(() => {
			(<any>$('[data-toggle="popover"]')).popover();
		})
	}

	invalidEntry() {
		if (this.isEmailError) {
			return true;
		}
		else {
			return false;
		}
	}

	validateEmail() {
		this.isEmailError = false;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (this.email === "" || !isValidEmail(this.email)) {
				this.errorMessage = this.invalidEmailFormat;
				this.isEmailError = true;
			}
		}, TIMEOUT_INTERVAL);
	}

	invalidateEmpty() {
		if (this.email.length > 0 && !isValidEmail(this.email)) {
			this.isEmailError = true;
			this.errorMessage = this.invalidEmailFormat;
		}

		if (isValidEmail(this.email)) {
			this.isEmailError = false;
		}
	}

	validateEmailBlur() {
		if (this.email === '') {
			this.errorMessage = this.emailCannotBeEmpty;
			this.isEmailError = true;

		} else if (!isValidEmail(this.email)) {
			this.errorMessage = this.invalidEmailFormat;
			this.isEmailError = true;
		}
	}


	sendResetPasswordLink(isResendLink?: boolean) {
		this.isEmailError = false;
		this.resentLink = false;
		this.email = this.email.trim();

		if (this.email === '') {
			this.isEmailError = true;
			this.errorMessage = this.emailCannotBeEmpty;
			this.readError = true;
			document.getElementById('inputEmail').focus();
			return;
		} else if (!isValidEmail(this.email)) {
			this.isEmailError = true;
			this.errorMessage = this.invalidEmailFormat;
			this.readError = true;
			document.getElementById('inputEmail').focus();
			return;
		} else if (this.redirectToOffice365IfInformaDomain(this.email)) {
			return;
		}

		this.resetPasswordRegister.forgotPassword(this.email, this.clientId)
			.subscribe(
				() => {
					if (!isResendLink) {
						this.forgotPasswordScreen = false;
						this.emailSentScreen = true;
					} else {
						this.resentLink = true;
					}
				},
				(err: any) => {
					this.setErrorStateVariables(err.metadata);
				}
			);
	}

	redirectToOffice365IfInformaDomain(email: string) {
		const domains = this.appState.get('domains');
		const emailParts = email.toLowerCase().trim().split('@');
		if (emailParts.length === 2) {
			if (domains && domains.length > 0 && domains.indexOf(emailParts[1]) > -1) {
				this.setHref(this.officeLogin);
			}
		}
		return false;
	}

	verifyToken(email: string, token: string) {
		this.isEmailError = false;

		this.email = email;
		this.token = token;
		//logging out user if logged in while resetting the password in forgot password flow
		this.appState.getTokenAndlogoutUser(false)
			.then(() => this.appState.loadUserProfileComponent(false))
			.catch((err: Error) => {
				console.log('Error in logging out user', err);
			});
		this.resetPasswordRegister.verifyResetPasswordToken(email, token)
			.subscribe(res => {
				this.resetPasswordScreen = true;
			}, err => {
				this.setErrorStateVariables(err.metadata);
			});
	}

	resetPassword() {
		this.isEmailError = false;
		this.isPasswordError = false;
		this.isConfirmPasswordError = false;

		if (!this.newPwd.trim()) {
			this.isPasswordError = true;
			this.errorMessage = this.passwordCannotBeEmpty;
			document.getElementById('inputEmail').focus();
			return;
		} else if (this.newPwd && !isValidPassword(this.newPwd)) {
			this.isPasswordError = true;
			this.errorMessage = '';
			document.getElementById('inputEmail').focus();
			return;
		}
		if (!this.cnfPwd.trim()) {
			this.isConfirmPasswordError = true;
			this.errorMessage = this.confirmPasswordCannotBeEmpty;
			document.getElementById('confirmPwd').focus();
			return;
		} 
		if (this.newPwd !== this.cnfPwd) {
			this.isConfirmPasswordError = true;
			this.errorMessage = this.newPassMatchCnfPass;
			document.getElementById('confirmPwd').focus();
			return;
		}
		this.resetPasswordRegister.resetPassword(this.email, this.newPwd, this.token, this.cnfPwd)
			.subscribe(
				res => {
					this.resetPasswordScreen = false;
					this.resetPasswordSuccessScreen = true;
				},
				err => {
					this.setErrorStateVariables(err.metadata);
				}
			);
	}

	async goToLogin() {
		await this._route.navigate(['/login'], { queryParamsHandling: 'preserve' });
	}

	private setErrorStateVariables(errorMetadata: any) {
		if (errorMetadata) {
			console.error(errorMetadata);
			this.errorMessage = errorMetadata.message.value;
			switch (errorMetadata.message.key) {
				case "UNEXPECTED_ERROR":
				case "EMAIL_ADDRESS":
				case "USER_NAME":
				case "NEW_PASSWORD":
				case "OLD_PASSWORD":
					this.isEmailError = true;
					this.isPasswordCriteriaError = true;
					this.isConfirmPasswordError = true;
					break;
				case "PASSWORD":
					this.isPasswordError = true;
					this.passwordHint = errorMetadata.message.value;
					break;
				case "RESET_PASSWORD_TOKEN_EXPIRED":
				case "RESET_PASSWORD_TOKEN_INVALID":
					this.isTokenExpired = true;
					break;
				default:
					break;
			}
		}
	}

	validatePassword() {
		this.passwordHint = this.standardPasswordHint;
		if (this.newPwd && !isValidPassword(this.newPwd)) {
			this.isPasswordError = true;
			this.isConfirmPasswordError = false;
			this.errorMessage = '';
		} else {
			this.isPasswordError = false;
		}
	}

	setHref(url: string) {
		this.document.location.href = url
	}
}
