import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { SignUpService } from './sign-up.service';
import { AuthapiService } from '../../services/authapi/authapi.service';
import { AppState } from '../../app.service';
import { Router } from '@angular/router';
import { UrlConfig } from '../../../url-config';
import { sortBy } from 'lodash-es';
import { finalize } from "rxjs/operators";
import { nameValidator, isValidPassword, validateEmail, MAX_CHARACTER_LIMIT } from '@tandfgroup/form-field-validator'

const TIMEOUT_INTERVAL = 1000;
const baseURL: any = new UrlConfig().getBaseApiUrl();

@Component({
  selector: 'sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: ['sign-up.component.scss'],
  providers: [SignUpService],
})
export class SignUpComponent {
  config = new UrlConfig();
  viewDescription = false;
  firstName = '';
  lastName = '';
  isChecked = false;
  optOut = false;
  country: string = '';
  allCountries: any = [];
  geoLocations: any = {};
  email = '';
  isValid: any = [];
  password = '';
  clientId = null;
  authorize: boolean;
  isIncorrectPassword = false;
  errorMessage: string = '';
  isIncorrectEmail = false;
  isUnexpectedError = false;
  emailSentScreen = false;
  isIncorrectFirstName = false;
  isIncorrectLastName = false;
  isIncorrectCountry = false;
  isErrorEmail = false;
  errorLengthExceeds = false;
  isSubmitted = false; // Is signup form is in processing state.
  resentEmail = false;
  notificationMsg = '';
  clientConfig;
  termsOfUseLink = '';
  privacyPolicyLink = '';
  brand = '';
  isPwdHintError = false;
  passwordHint: string = "Password must be at least 8 characters long and include at least one of each of 0-9, a-z, A-Z and symbol (e.g. ! # ? $)";
  AccExplaination: string = "This account can be used to access the following services: Author Portal, eBooks, Sustainable Development Goals Online, Primary Source Collections";
  isCheckBoxNotClicked = false;
  errorMsgFirstName: string = '';
  errorMsgLastName: string = '';
  errorMsgEmail: string = '';
  errorMsgTerms: string = '';
  errorMsgCountry: string = '';
  consentGiven = true;
  officeLogin = baseURL + 'user/auth/office';
  siteKey: string = '';
  isValidated: boolean = false;
  blankForm: boolean = false;
  invalidName: boolean = false;
  invalidEmail: boolean = false;
  focusedCheckbox1: boolean = false;
  focusedCheckbox2: boolean = false;
  isInformaEmail: boolean = false;
  isCaptchaValid = true;
  recaptchaToken: string;
  hCaptchaErrMsg: string = '';
  state: string = '';
  clientDetails: any;
  viewPswd: string = 'icon-eye-blocked';
  firstNameMaxLimit: number = null;
  lastNameMaxLimit: number = null;

  @ViewChild('signupBtn', { static: true }) loginButton: ElementRef;
  private timer;

  constructor(
    private signUpService: SignUpService,
    private zone: NgZone,
    public appState: AppState,
    private _route: Router,
    private authapiService: AuthapiService
  ) {
    window['onSubmit'] = (token: string) => {
      this.zone.run(() => {
        this.isCaptchaValid = true;
        this.recaptchaToken = token;
      })
    }
  }

  ngOnInit() {
    // Get client config
    /*Temporary fix - Comment this code instead of deleting it in case we need to do this again
   this.showTopNotification = true;
   this.isSuccess = false;
   this.notificationMsg = `Sorry, the signup service is temporarily unavailable, we are working on a solution and will aim to restore the service soon.
       If registration to the site is urgent, please contact us at support@taylorfrancis.com`;
   Temporary fix ends here*/
    this.firstNameMaxLimit = MAX_CHARACTER_LIMIT.firstName;
    this.lastNameMaxLimit = MAX_CHARACTER_LIMIT.lastName;
    this.clientDetails = this.appState.getClientConfig();
    this.clientConfig = this.config.getClientConfig(this.appState.get('brand'));
    this.termsOfUseLink = this.clientConfig.termsOfUseLink;
    this.privacyPolicyLink = this.clientConfig.privacyPolicy || 'https://informa.com/privacy-policy';
    this.fetchCountries();
    if(this.clientDetails?.signupDescription) this.viewDescription = true
    if (Object.keys(this.appState.get('queryParams')).length > 0) {
      this.clientId = this.appState.get('queryParams')['client_id'];
      this.state = this.appState.get('queryParams')['state'];
      this.authorize = Boolean(this.appState.get('queryParams')['authorize']);
      if (
        Object.keys(this.appState.get("queryParams")).indexOf("brand") !== -1
      ) {
        this.brand = this.appState.get("queryParams")["brand"];
      } else {
        this.brand = '';
      }
    }
    if (this.isE2e()) {
      this.siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    } else {
      this.siteKey = 'cf72fcbd-b238-4960-ae54-8f7d98411531';
    }
  }

  validate() {
    if (this.validateForm()) {
      this.zone.run(() => {
        this.register(this.recaptchaToken);
      })
    }
    else {
      this.blankForm = true;
    }
  }

  ngAfterViewInit() {
    $(() => {
      (<any>$('[data-toggle="popover"]')).popover();
    })
    grecaptcha?.render('hcaptcha');
  }

  register(recaptchaToken: string) {
    this.isSubmitted = true; // Disable signup button.
    const countryData = this.geoLocations.countries.find(value => value.name === this.country);
    let countryCode = '';
    if (countryData) {
      countryCode = countryData.countryCode;
    }
    let address = {
      country: this.country,
      countryCode: countryCode
    }

    if (validateEmail(this.email).valid && this.redirectToOffice365IfInformaDomain(this.email)) {
      return;
    } else {
      this.signUpService.register({
        username: this.email,
        email: this.email,
        password: this.password,
        firstName: this.firstName.replace(/\s+/g, ' ').trim(),
        lastName: this.lastName.replace(/\s+/g, ' ').trim(),
        displayName: (this.firstName + ' ' + this.lastName).replace(/\s+/g, ' ').trim(),
        clientId: this.clientId,
        clientName: this.clientDetails?.name,
        brand: this.brand,
        recaptchaToken,
        optOut: this.optOut,
        consentGiven: this.consentGiven,
        address,
        piiValidatedAt: Date.now()
      }).pipe(
        finalize(() => {
          /* After processing signup service, enable the sign-up button for the user.
             In case of server error, they can resubmit. */
          this.isSubmitted = false;
        }))
        .subscribe(
          res => {
            this.emailSentScreen = true;
          },
          err => {
            this.setErrorStateVariables(err.metadata);
          }
        );
    }
  }

  async navigateToLogin() {
    let redirectUrl = "/login";
    if (this.clientId) {
      redirectUrl += "?authorize=true" + "&client_id=" + this.clientId + "&state=" + this.state;
    }
    if (this.brand) {
      redirectUrl += "&brand=" + this.brand;
    }
    await this._route.navigateByUrl(redirectUrl);
  }

  redirectToOffice365IfInformaDomain(email: string) {
    let domains = this.appState.get('domains');
    const emailParts = email.toLowerCase().trim().split('@');
    if (emailParts.length === 2 && domains && domains.length > 0 && domains.indexOf(emailParts[1]) > -1) {
      jQuery(".form-bowl :input:not(:focus)").attr("disabled", "disabled");
      jQuery(".optin-text, .terms-check-box, .h-captcha").addClass("class2");
      this.errorMsgEmail = 'This email has been configured for Informa SSO. Try signing in instead.';
      setTimeout(() => {
        this.isInformaEmail = true;
        this.isErrorEmail = true;
        this.isIncorrectFirstName = false;
        this.isIncorrectLastName = false;
        this.isIncorrectCountry = false;
        this.isIncorrectPassword = false;
        this.isSubmitted = true;
        this.isCaptchaValid = true;
      }, TIMEOUT_INTERVAL + 2);
      return true;
    } else {
      this.isInformaEmail = false;
      this.isSubmitted = false;
      this.isErrorEmail = false;
      this.errorMsgEmail = '';
      jQuery(".form-bowl :input:not(:focus)").removeAttr("disabled");
      jQuery(".optin-text, .terms-check-box, .h-captcha").removeClass("class2");
      return false;
    }
  }

  private setErrorStateVariables(errorMetadata: any) {
    if (errorMetadata) {
      switch (errorMetadata.message.key) {
        case 'EMAIL_ADDRESS':
          this.isIncorrectEmail = true;
          this.errorMsgEmail = errorMetadata.message.value;
          this.errorLengthExceeds = this.errorMsgEmail.length>=70;
          document.getElementById("inputEmail").focus();
          break;
        case 'RECAPTCHA':
          this.isCaptchaValid = false;
          this.hCaptchaErrMsg = errorMetadata.message.value;
          grecaptcha?.reset();
          break;
        case 'UNEXPECTED_ERROR':
          this.isUnexpectedError = true;
          break;
        case 'INVALID_DISPLAY_NAME':
          this.errorMsgFirstName = 'First Name is invalid';
          this.errorMsgLastName = 'Last Name is invalid';
          this.isIncorrectFirstName = true;
          this.isIncorrectLastName = true;
          break;
          case 'INVALID_FIRST_NAME':
            this.errorMsgFirstName = errorMetadata.message.value || 'First Name is invalid';
            this.isIncorrectFirstName = true;
            document.getElementById("firstName").focus();
            break;
          case 'INVALID_LAST_NAME':
            this.errorMsgLastName = errorMetadata.message.value || 'Last Name is invalid';
            this.isIncorrectLastName = true;
            document.getElementById("lastName").focus();
          break;
      }
    }
  }

  // This method is invoked when user clicks on resend email link.
  public resendMail() {
    this.resentEmail = false;
    this.authapiService.resendverifyLink(this.email, this.clientId,
      this.brand, this.clientDetails?.name).subscribe(
        res => {
          this.resentEmail = true;
          this.notificationMsg = "Verification link has been resent to " + this.email + ".";
        },
        err => {
          this.setErrorStateVariables(err.metadata);
        }
      );
  }

  removeNameError(name) {
    if(name === 'firstName')
      this.isIncorrectFirstName = false;
    else if(name === 'lastName')
      this.isIncorrectLastName = false;
  }

  validateEmail() {
    this.isErrorEmail = false;
    this.isIncorrectEmail = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const validateEmailRes = validateEmail(this.email);
      if (this.email === "" || !validateEmailRes.valid) {
        this.errorMsgEmail = this.email ? validateEmailRes.description : "Email address required";
        this.errorLengthExceeds = this.errorMsgEmail.length>=70;
        this.invalidEmail = true;
        this.isErrorEmail = true;
      }
    }, TIMEOUT_INTERVAL);
  }

  validateEmailBlur() {
    if(this.isInformaEmail){
      this.isErrorEmail = true;
    }
    else{
      this.isErrorEmail = false;
    }
    const validateEmailRes = validateEmail(this.email);
    if (this.email === "" || !validateEmailRes.valid) {
      this.errorMsgEmail = this.email ? validateEmailRes.description : "Email address required";
      this.errorLengthExceeds = this.errorMsgEmail.length>=70;
      this.isErrorEmail = true;
    }
  }

  validateName(name) {
    if(name === 'firstName'){
      this.isIncorrectFirstName = false;
    }
    else if(name === 'lastName'){
      this.isIncorrectLastName = false;
    }
    clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.validateNameAndSetErrorsIfAny(name);
      }, TIMEOUT_INTERVAL);
  }

  validateNameAndSetErrorsIfAny(fieldName: 'firstName' | 'lastName') {
    const value = fieldName === 'firstName' ? this.firstName : this.lastName;
    const fieldUiName = {
      firstName: 'First Name',
      lastName: 'Last Name',
    };
    let errorMessage = '';
    const validateNameRes = nameValidator(value, fieldName);
    if (!value) {
      errorMessage = `${fieldUiName[fieldName]} required`;
    } else if (!validateNameRes.valid) {
      errorMessage = validateNameRes.description || `${fieldUiName[fieldName]} invalid`;
    }
    if (fieldName === 'firstName') {
      this.isIncorrectFirstName = !!errorMessage;
      this.errorMsgFirstName = errorMessage;
    } else {
      this.isIncorrectLastName = !!errorMessage;
      this.errorMsgLastName = errorMessage || '';
    }
  }

  validateCountry() {
    this.isIncorrectCountry = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.country.length < 2) {
        this.isIncorrectCountry = true;
      }

    }, TIMEOUT_INTERVAL);
  }

  validateCountryBlur() {
    if (this.country.length < 2) {
      this.errorMsgCountry = "Country/Region required";
      this.isIncorrectCountry = true;
    }
  }

  validatePassword() {
    this.isPwdHintError = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.password === "" || !isValidPassword(this.password)) {
        this.isIncorrectPassword = true;
        this.isPwdHintError = true;
        this.errorMessage = '';
      }
    }, TIMEOUT_INTERVAL);
  }

  validatePasswordBlur() {
    if (this.password === "" || !isValidPassword(this.password)) {
      this.isIncorrectPassword = true;
      this.isPwdHintError = true;
      this.errorMessage = '';
    } else {
      this.isIncorrectPassword = false;
      this.isPwdHintError = false;
    }
  }
  // This method is to turn off the error flag for checkbox when its checked
  resetCheckboxFlag() {
    this.isCheckBoxNotClicked = false;
    this.errorMsgTerms = '';
  }

  //This method is called when sign up button is clicked
  validateForm(): boolean {
    this.isCheckBoxNotClicked = false;
    this.email = this.email.trim();
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();

    if (this.firstName.length < 1) {
      this.errorMsgFirstName = "First Name required";
      this.isIncorrectFirstName = true;
    }
    if (this.lastName.length < 1) {
      this.errorMsgLastName = "Last Name required";
      this.isIncorrectLastName = true;
    }
    if (this.country.length < 2) {
      this.errorMsgCountry = "Country/Region required";
      this.isIncorrectCountry = true;
    }
    const validateEmailRes = validateEmail(this.email);
    if (this.email === "" || !validateEmailRes.valid) {
      this.errorMsgEmail = this.email ? validateEmailRes.description : "Email address required";
      this.errorLengthExceeds = this.errorMsgEmail.length>=70;
      this.isErrorEmail = true;
    }
    if (this.password === "" || !isValidPassword(this.password)) {
      this.isIncorrectPassword = true;
      this.isPwdHintError = true;
      this.errorMessage = '';
    }

    if (!this.isChecked) {
      this.isCheckBoxNotClicked = true;
      this.errorMsgTerms = 'Agreement to Terms & Conditions required';
    }
    if (this.isChecked) {
      this.isCheckBoxNotClicked = false;
      this.errorMsgTerms = '';
    }

    if (!this.recaptchaToken) {
      this.isCaptchaValid = false;
      this.hCaptchaErrMsg = 'Your response to the CAPTCHA appears to be invalid. Please re-verify that you are a human.';
    }

    if (!this.isValidated) {
      this.readErrorMsgs();
    }

    // Check all the invalid state
    this.isValid =
      !this.isCheckBoxNotClicked &&
      !this.isPwdHintError &&
      !this.isIncorrectFirstName &&
      !this.isIncorrectLastName &&
      !this.isIncorrectCountry &&
      !this.isIncorrectPassword &&
      !this.isErrorEmail &&
      this.isCaptchaValid;

    this.isValidated = this.isValid;
    return this.isValid;
  }


  // This method is to check if we are running e2e test cases
  // Test site key for recaptcha is used for e2e
  isE2e(): boolean {
    if (window.localStorage.getItem("e2e") === "true") {
      return true;
    } else {
      return false;
    }
  }

  fetchCountries() {
    this.signUpService.fetchCountries().then(response => {
      this.geoLocations = response;
      this.allCountries = sortBy(response.countries, 'name');
    }).catch((err)=>console.log(err))
  }

  readErrorMsgs() {
    const chk: boolean = true;
    switch (chk) {
      case (this.isIncorrectFirstName):
        document.getElementById("firstName").focus();
        break;
      case (this.isIncorrectLastName):
        document.getElementById("lastName").focus();
        break;  
      case this.isErrorEmail:
        document.getElementById("inputEmail").focus();
        break;
      case this.isIncorrectPassword:
        document.getElementById("inputPassword").focus();
        break;
      case this.isPwdHintError:
        document.getElementById("inputPassword").focus();
        break;
      case this.isCheckBoxNotClicked:
        document.getElementById("termsCheckBox").focus();
        break;
      case this.isIncorrectCountry:
        document.getElementById("country").focus();
        break;
      case !this.isCaptchaValid:
        document.getElementById("hcaptcha").focus();
        break;
    }

  }
}
